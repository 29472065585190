import cer_1 from '../image/Certificates/img_1.webp'
import cer_2 from '../image/Certificates/img_2.webp'
import cer_3 from '../image/Certificates/img_3.webp'

import img_1 from '../image/Portfolio/img_13.jpg'
import img_2 from '../image/Portfolio/img_2.webp'
import img_3 from '../image/Portfolio/img_3.webp'
import img_4 from '../image/Portfolio/img_10.webp'
import img_5 from '../image/Portfolio/img_1.webp'
import img_6 from '../image/Portfolio/img_6.webp'
import img_7 from '../image/Portfolio/img_7.webp'
import img_8 from '../image/Portfolio/img_8.webp'
import img_9 from '../image/Portfolio/img_9.webp'

export const arrPortfolio = [{image: img_1, title: 'древесная шерсть'}, {image: img_2, title: 'древесная шерсть'}, {image: img_3, title: 'древесная шерсть'}, {image: img_4, title: 'древесная шерсть'}, {image: img_5, title: 'древесная шерсть'}, {image: img_6, title: 'древесная шерсть'}, {image: img_7, title: 'древесная шерсть'}, {image: img_8, title: 'древесная шерсть'}, {image: img_9, title: 'древесная шерсть'},];

export const arrAdvantages = [{text:'Собственное ПРОИЗВОДСТВО'}, {text:'Экологичный ПРОДУКТ'}, {text:'Честная ЦЕНА'}, {text:'Гарантированное КАЧЕСТВО'}, {text:'Из сухой ДРЕВЕСИНЫ'}, {text:'Лабораторные ИСПЫТАНИЯ'},];

export const arrCertificates = [{image: cer_1, title: 'Cертификат'}, {image: cer_2, title: 'Cертификат'}, {image: cer_3, title: 'Cертификат'}, {image: cer_1, title: 'Cертификат'}, {image: cer_2, title: 'Cертификат'}, {image: cer_3, title: 'Cертификат'},];